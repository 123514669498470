import Vue from "vue/dist/vue.min";

const RestrictedMedia = () => import(/* webpackChunkName: "RestrictedMedia" */ "./RestrictedMedia.vue");

function init() {
    document.querySelectorAll('[data-vue="restricted-media"]').forEach((el) => {
        return new Vue({
            el,
            components: { RestrictedMedia },
        });
    });
}

if (document.readyState !== "loading") {
    init();
} else {
    document.addEventListener("DOMContentLoaded", () => {
        init();
    });
}
